  
  main {
    text-align: center;
  }
  
  main h1 {
    margin-top: 20px;
  }
  
  main p {
    margin: 20px 0;
  }

  .content-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-70%);
    width: 80%;
    max-width: 800px;
    text-align: center;
    background-color: rgba(0,0,0, 0.0); /* Optional: Adds a semi-transparent background */
    padding: 20px; /* Optional: Adds padding around the text and buttons */
    
  }

  .privacy-container {
    position: absolute;

    left: 50%;
    transform: translateX(-50%);
    max-width: 800px;
    width: 100%;
    text-align: left;
    background-color: rgba(0,0,0, 0.4); /* Optional: Adds a semi-transparent background */
    padding: 20px; /* Optional: Adds padding around the text and buttons */
  }
  
  header {
    background-image: url('../public/bg_1.png');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    height: 100vh; /* Full screen height */
    width: 100%; /* Full screen width */
  }

    
  .logo-header{
    max-width: 400px;
    width: 50%;
  }
  
    .bottom-of-screen {
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      margin-bottom: 5%;
    }


  .small-white-link {
    font-size: 10px; /* Makes the text smaller */
    color: white; /* Changes the text color to white */
    text-decoration: underline; /* Removes the underline */
    margin-right: 5px;
    margin-left:5px;
  }
  
  .small-white-link:hover {
    text-decoration: underline; /* Optional: Adds underline on hover */
  }
  
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: black;
    color: white;
  }
  